import React from 'react';
import './App.css';

import NumberFormat from 'react-number-format';
import { sha256 } from 'js-sha256'

const solution = '2a014638d1fd0b1596fca17494121e2374604c471785b841b6bb86f66a37fa48';

class ETHandEurAmount extends React.Component {

  render() {
    const incomesEUR = this.props.amountETH * this.props.rate;
    return (<span>
      { this.props.amountETH } ETH 
      <br />
      <em>(~ <NumberFormat value={incomesEUR} displayType={'text'} thousandSeparator={false} suffix={' €'} decimalScale={2} thousandSeparator={' '} />)</em>
    </span>);
  }
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyFound: false,
      clef: '',
      hash: '',
      error: null,
      isLoaded: false,
      balance: 0,
      effectivebalance: 32000000000,
      validatorindex: 42458,
      pubkey: '0xa4af992597de80d4602eab62bf300b211f2878bff2ec64fe5003026b8d56b7fc57cf9a6380f5c131f4cc7f34ca294f32',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const guess = sha256(event.target.value.replace('ï','i').toLowerCase());
    if(guess === solution) {
      this.setState({keyFound: true});
      setTimeout(() => {
        this.setState({openChest: true});
        
        fetch("https://beaconcha.in/api/v1/validator/42458/performance")
        .then(res => res.json())
        .then(
          (resultPerformance) => {

            fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=EUR")
            .then(res => res.json())
            .then(
              (resultCoingecko) => {
                this.setState({
                          isLoaded: true,
                          balance: resultPerformance.data.balance,
          
                          performance1d: resultPerformance.data.performance1d / 1e9,
                          performance7d: resultPerformance.data.performance7d / 1e9,
                          performance31d: resultPerformance.data.performance31d / 1e9,
                          performance365d: resultPerformance.data.performance365d / 1e9,

                          rate: resultCoingecko.ethereum.eur,
                        });
              },
              (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
            )
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      }, 2000);
    }
  }

  render() {

    const { error, openChest, isLoaded, keyFound, balance, effectivebalance, pubkey, validatorindex, rate, performance1d, performance7d, performance31d, performance365d } = this.state;
    const incomesETH = (balance - effectivebalance) / 1e9;
    const incomesEUR = incomesETH * rate
    if(keyFound) {
      if(openChest) {
        if(isLoaded) {
          return (
            <div className="App">
              <div className="Parchemin">
                <h1>Validator: <a href={"https://beaconcha.in/validator/" + pubkey.slice(2)}>{validatorindex}</a></h1>
                <h3>{ incomesETH } ETH <em>(~ <NumberFormat value={incomesEUR} displayType={'text'} thousandSeparator={false} suffix={' €'} decimalScale={2} thousandSeparator={' '} />)</em> </h3>
                <p>Dernier Jour: <br />	+<ETHandEurAmount amountETH={performance1d} rate={rate}/></p>
                <p>Dernière Semaine: <br /> +<ETHandEurAmount amountETH={performance7d} rate={rate}/></p>
                <p>Dernier Mois: <br /> +<ETHandEurAmount amountETH={performance31d} rate={rate}/></p>
                <p>Dernière Année: <br /> +<ETHandEurAmount amountETH={performance365d} rate={rate}/></p>
                <p>APR: <br /> ~ {Math.floor(performance7d * 52 * 100 / 32) /* weeklyPerf * weeksPerYear * % / 32ETH */ } %</p>

                <p className='bottomdown'><a href={"https://beaconcha.in/validator/" + pubkey.slice(2)}><em>plus d'info...</em></a></p>
              </div>
            </div>
          );
        } else {
          return (
            <div className="App">
              <img src={`${process.env.PUBLIC_URL}/open.png`} className="chest-move" alt="logo" />
            </div>
          );
        }        
      } else {
        return (
          <div className="App">
            <img src={`${process.env.PUBLIC_URL}/close.png`} className="chest-move" alt="logo" />
          </div>
        );
      }
    } else {
      return (
        <div className="App">
            <img src={`${process.env.PUBLIC_URL}/close.png`} className="App-logo" alt="logo" />
             
            <form onSubmit={this.handleSubmit}>
              <label>
                <input type="text" onChange={this.handleChange} />
                {this.state.hash}
              </label>
            </form>
        </div>
      );
    }
  }
}

export default App;
